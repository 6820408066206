
import Vue from "vue";
import api from '@api/chat/apiMethod'
import { mapGetters } from "vuex";
import { h } from "vue"
const PortalHeader = () => import("../../component/common/PortalHeader.vue");
const PortalFooter = () => import("../../component/common/PortalFooter.vue");
const ChatModal = () => import('../../component/ChatModal.vue')

export default Vue.extend({
    name: "ActivityDetail",
    components: {
        PortalHeader,
        PortalFooter,
        ChatModal
    },
    computed: {
        ...mapGetters(["userInfo", "comConfig"]),
    },
    data() {
        return {
            pageTurn: {},
            dataSource: [],
            columns: [
                {
                    title: "用户名",
                    dataIndex: "name",
                    key: "name",
                    align: "center",
                },
                {
                    title: "创建时间",
                    dataIndex: "createTime",
                    key: "createTime",
                    align: "center",
                },
                {
                    title: "更新时间",
                    dataIndex: "updateTime",
                    key: "updateTime",
                    align: "center",
                },
                {
                    title: "是否有新消息",
                    dataIndex: "isItNew",
                    key: "isItNew",
                    align: "center",
                    customRender:(text:any, record:any)=>{
                        return h("a", {}, record.isItNew==1?'有':'无')
                    }
                },
                {
                    title: "操作",
                    dataIndex: "operation",
                    key: "operation",
                    align: "center",
                    customRender:(text:any, record:any)=>{
                        let self:any = this
                        return h("a", 
                        {on:{
                            click: ()=>{ self.viewDetail(record) }
                        }}, 
                        '查看')
                    }
                },
            ],
            tableLoading: false
        };
    },
    methods: {
        viewDetail(info:any){
            console.log("info", info)
            let chatModal:any = this.$refs.chatModal
            chatModal.showModal(1, info.id, info.userId)
        },
        tableChange(e:any){
            this.getMessageList({page: e.current, pageSize: e.pageSize})
        },
        getMessageList(params:any) {
            const self = this as any;
            self.tableLoading = true
            api.getMessageList({
                data: {
                    page:1,
                    pageSize: 10,
                    ...params
                },
                success(res: any) {
                    console.log(res)
                    self.dataSource = res.data;
                    self.pageTurn = { current: res.curPage, pageSize: res.pageSize, total: res.totalSize }
                    self.tableLoading = false
                },
                error(){
                    self.tableLoading = false
                }
            });
        },
    },
    async mounted() {
        this.getMessageList('')
    },
});
